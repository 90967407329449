import { AppShellNavbar, Card, Center, Flex, Group, Text, useMantineTheme } from '@mantine/core';
import { PebbleLogoWithText } from 'components/ui';
import Link from 'next/link';
import { trackAction, Actions } from 'utils/amplitude';
import { Auth } from '@pebble/common';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import { PoweredByPebble } from '@icons';
import Logo from '../Logo';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import ActiveMainSearchBarDesktop from 'components/ActiveMainSearchBarDesktop/ActiveMainSearchBarDesktop';
import InactiveHomepageSearchBarDesktop from 'components/InactiveHomepageSearchBarDesktop/InactiveHomepageSearchBarDesktop';
import NavMobile from '../NavMobile /NavMobile';
import classes from './PebbleNavbar.module.scss';
import classNames from 'classnames';
import { MagnifyingGlass } from '@phosphor-icons/react';

interface IPebbleNavbarProps {
  height: number;
}

// All possible route variants that we currently embed in iframes
export enum Routes {
  SUPPLIER_ACTIVITIES_ROUTE = '/supplier/[slug]/activities',
  ACTIVITY_DETAILS_ROUTE = '/activity/[slug]',
  SESSION_SELECT_ROUTE = '/iframe/sessionSelect/[id]',
}

enum RouteTypes {
  SUPPLIER_ACTIVITIES = 'supplier-activities',
  ACTIVITY_DETAILS = 'activity-details',
  SESSION_SELECT = 'session-select',
}

const PebbleNavbar: React.FC<IPebbleNavbarProps> = ({ height }) => {
  const userToken = Auth.useGetJWT();
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const {
    toggle,
    activeDesktopFilter,
    locationString,
    isHomepage,
    resetAllFilters,
    setLocationString,
    setActiveDesktopFilter,
    setFetching,
    setSearchFilterState,
  } = useSavedSearchValues();

  const isMobile = useMediaQuery('(max-width: 960px)', false);

  const theme = useMantineTheme();

  const { isEmbed, iframeLocation } = useContext(EmbedContext) as EmbedContextType;

  const isCheckout = router.pathname === '/checkout/details';
  const isFurtherInfo = router.pathname.includes('further-info');
  const isLogin = router.pathname === '/login';
  const isSupplierPage =
    router.pathname.includes('supplier') && !router.pathname.includes('activities');

  const getEmbedRoute = () => {
    if (router.pathname.includes(Routes.SUPPLIER_ACTIVITIES_ROUTE)) {
      return RouteTypes.SUPPLIER_ACTIVITIES;
    } else if (router.pathname.includes(Routes.ACTIVITY_DETAILS_ROUTE)) {
      return RouteTypes.ACTIVITY_DETAILS;
    } else if (router.pathname.includes(Routes.SESSION_SELECT_ROUTE)) {
      return RouteTypes.SESSION_SELECT;
    }
  };

  const currentRoute = getEmbedRoute();

  const getMiddleComponent = () => {
    if (isCheckout || isHomepage || isFurtherInfo || isSupplierPage || isLogin) {
      return;
    } else if (isMobile) {
      return (
        <Card
          className={classes.card}
          p={'0.5rem'}
          onClick={() => {
            toggle();
            trackAction(Actions.SEARCH_BAR_SELECT);
          }}
        >
          <Group wrap="nowrap" gap={0}>
            <MagnifyingGlass
              className={classes.mobileSearchIcon}
              size={18}
              color={theme.colors.blue[8]}
              weight="bold"
            />
            <Text py={4} className={classes.filterHeader} truncate="end">
              {locationString ? `Near ${locationString}` : 'Find an activity'}
            </Text>
          </Group>
        </Card>
      );
    } else if (activeDesktopFilter && !isMobile) {
      return <ActiveMainSearchBarDesktop />;
    } else {
      return <InactiveHomepageSearchBarDesktop />;
    }
  };

  /* We only want to render this component on the client as it uses 
    values behind the # in the url which the server does not have access to.
    This breaks React's hydration. */
  if (!isClient) {
    return null;
  }

  return (
    <>
      {isEmbed ? (
        <div
          className={classNames({
            [classes.supplierActivities]: currentRoute === RouteTypes.SUPPLIER_ACTIVITIES,
            [classes.activityDetails]: currentRoute === RouteTypes.ACTIVITY_DETAILS,
            [classes.sessionSelect]: currentRoute === RouteTypes.SESSION_SELECT,
          })}
        >
          <PoweredByPebble />
        </div>
      ) : (
        !iframeLocation && (
          <AppShellNavbar h={height} className={classes.nav}>
            <Flex className={classes.group}>
              <Link
                href="/"
                passHref
                onClick={() => {
                  trackAction(Actions.PEBBLE_ICON);
                  resetAllFilters();

                  setSearchFilterState((prevState) => ({
                    ...prevState,
                    coordinates: null,
                  }));

                  setLocationString('');
                  setActiveDesktopFilter(false);
                  setFetching(false);
                }}
                title="home"
                aria-label="Go to home page."
              >
                {isMobile && !isHomepage ? (
                  <Logo className={classes.pebbleLogo} />
                ) : (
                  <PebbleLogoWithText
                    className={classNames(classes.pebbleLogo, {
                      [classes.mobileLogoWithText]: isMobile && isHomepage,
                    })}
                  />
                )}
              </Link>
              {!isHomepage && getMiddleComponent()}
              <Center
                h={height}
                className={classNames(classes.burgerWrapper, {
                  [classes.inactiveSearchBarPosition]: !activeDesktopFilter && !isMobile,
                })}
              >
                <NavMobile userToken={userToken}></NavMobile>
              </Center>
            </Flex>
          </AppShellNavbar>
        )
      )}
    </>
  );
};

export default PebbleNavbar;
